<template>
  <component v-if="route.meta.layout" :is="route.meta.layout">
    <router-view></router-view>
  </component>
  <template v-else>
    <router-view></router-view>
  </template>
</template>

<script setup>
import '@/assets/stylesheets/reset.css';
import '@/assets/stylesheets/common.css';
import '@/assets/fonts/pretendard-variable.css';
import '@/assets/fonts/noto-sans.css';
import '@/assets/fonts/suit-variable.css';
import '@/assets/fonts/bungee-outline.css';

import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMq } from 'vue3-mq';

const route = useRoute();
const mq = useMq();

watch(
  () => mq.current,
  (newValue, oldValue) => {
    if (oldValue) {
      document.body.classList.remove(oldValue);
    }
    document.body.classList.add(newValue);
  },
  { immediate: true }
);
</script>
