<template>
  <SidebarComponent></SidebarComponent>
  <div class="workspace-wrapper">
    <slot></slot>
  </div>
  <FooterComponent></FooterComponent>
  <AlertMobile></AlertMobile>
</template>

<script setup>
import SidebarComponent from '@/components/SidebarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import AlertMobile from '@/components/AlertMobile.vue';

import { onMounted, onUnmounted } from 'vue';

onMounted(async () => {
  document.body.classList.add('workspace');
});

onUnmounted(() => {
  document.body.classList.remove('workspace');
});
</script>

<style>
.workspace #app {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'sidebar workspace'
    'footer   footer  ';
  min-width: 1200px;
}
.workspace .sidebar {
  grid-area: sidebar;
}
.workspace .workspace-wrapper {
  grid-area: workspace;
  display: flex;
}
.workspace main {
  flex: 1;
  padding: 45px 25px;
  background-color: var(--Workspace);
}
.workspace footer {
  grid-area: footer;
}
</style>
