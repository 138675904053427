<template>
  <section :class="['accordion', { on: isOpen }]">
    <button type="button" @click="isOpen = !isOpen">
      <slot name="titile"></slot>
      <span class="sprite sprite--accordion sprite--12"></span>
    </button>
    <div v-show="isOpen" class="accordion__content">
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  isDefaultOpen: {
    type: Boolean,
    required: false,
  },
});

const isOpen = ref(props.isDefaultOpen);
</script>

<style scoped>
.accordion > button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor--canvas .accordion {
  margin-top: 16px;
  margin-right: 15px;
  border-radius: 16px;
  border: 1px solid var(--Gray-30);
  background-color: var(--White);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.editor--canvas .accordion.on {
  overflow: visible;
}
.editor--canvas .editor__mockup-wrapper.accordion.on {
  overflow: hidden;
}
.editor--canvas .accordion > button {
  width: 100%;
  padding: 12px;
  background-color: var(--Gray-30);
}
.editor--canvas .accordion.on > button {
  background-color: var(--White);
  border-radius: 16px;
}
.editor--canvas .accordion__content {
  padding: 0 12px 10px;
}

.company-info .accordion > button {
  flex-direction: row-reverse;
  gap: 8px;
}
.company-info .accordion__content {
  margin-top: 16px;
}
</style>
