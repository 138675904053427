<template>
  <div class="sidebar scroll--inner">
    <header>
      <h4 class="body-18-b">
        안녕하세요. <br />
        {{ userName }}님
      </h4>
    </header>
    <nav>
      <div class="sidebar__main">
        <h3 class="body-18-m">Main Menu</h3>
        <ul>
          <li>
            <router-link to="/projects">
              <span class="sprite sprite--projects"></span>
              <p class="body-18-r">내 프로젝트</p>
            </router-link>
          </li>
          <li>
            <router-link to="/products">
              <span class="sprite sprite--packages"></span>
              <p class="body-18-r">패키지 만들기</p>
            </router-link>
          </li>
          <li>
            <router-link to="/cart">
              <span class="sprite sprite--cart"></span>
              <p class="body-18-r">장바구니</p>
            </router-link>
          </li>
          <li>
            <router-link to="/order-history">
              <span class="sprite sprite--order-history"></span>
              <p class="body-18-r">구매 내역</p>
            </router-link>
          </li>
          <li>
            <router-link to="/delivery">
              <span class="sprite sprite--shipping"></span>
              <p class="body-18-r">제작 / 배송현황</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar__others">
        <h3 class="body-18-m">Others</h3>
        <ul>
          <li>
            <router-link to="/mypage">
              <span class="sprite sprite--profile"></span>
              <p class="body-18-r">마이페이지</p>
            </router-link>
          </li>
          <li>
            <button class="body-18-r" type="button" @click="store.logout()">로그아웃</button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const store = useUserStore();

const { userName } = storeToRefs(store);
</script>

<style scoped>
.sidebar {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  width: 345px;
  height: 100vh;
}
header {
  padding: 46px 50px 30px;
}
nav {
  padding: 10px 50px calc(46px + 49px);
  color: var(--Gray-50);
}
nav ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
nav ul a,
nav ul button {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 60px;
  border: 1px solid transparent;
}
nav ul a:hover,
nav ul button:hover {
  color: var(--Gray-40);
}
nav ul a.router-link-active {
  width: auto;
  margin: 0 -26px;
  padding: 0 28px;
  border-radius: 48px;
  background-color: var(--Key);
  color: var(--Black);
  border: 1px solid var(--Black);
}
nav ul button {
  padding: 0 56px;
}
.sidebar__main ul {
  margin-top: 28px;
}
.sidebar__others {
  margin-top: 115px;
}
.sidebar__others ul {
  margin-top: 17px;
}
</style>
