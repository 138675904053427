<template>
  <header>
    <router-link to="/" class="header__logo sprite sprite--logo">MintPrime</router-link>
    <template v-if="$mq.current === 'pc'">
      <nav v-if="nav" class="header__nav">
        <ul>
          <li><router-link to="/support">Contact us</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/samples">Details</router-link></li>
        </ul>
      </nav>
      <div>
        <router-link to="/projects" class="body-14-r">{{
          !store.isLogined ? ' 나만의 브랜딩 만들기' : '나의 브랜딩'
        }}</router-link>
        <DropdownComponent v-if="store.isLogined" class="dropdown--user body-14-r">
          <template #btn>
            {{ userName }}
            <span class="sprite sprite--dropdown sprite--16">더보기</span>
          </template>
          <template #list>
            <li>
              <router-link to="/mypage">마이페이지 </router-link>
            </li>
            <li>
              <button type="button" @click="store.logout()">로그아웃</button>
            </li>
          </template>
        </DropdownComponent>
      </div>
    </template>
    <template v-else>
      <button type="button" class="sprite sprite--hamburger" @click="isOpenMenu = true"></button>
      <section v-if="isOpenMenu" class="drawer-menu">
        <div class="drawer-menu__head">
          <button type="button" class="sprite sprite--close" @click="isOpenMenu = false">
            close
          </button>
        </div>
        <div class="drawer-menu__content">
          <p class="head-48-b">Design Your Brand</p>
          <nav>
            <ul>
              <li class="head-24-sb">
                <router-link to="/support" @click="isOpenMenu = false">Contact us</router-link>
              </li>
              <li class="head-24-sb">
                <router-link to="/about" @click="isOpenMenu = false">About</router-link>
              </li>
              <li class="head-24-sb">
                <router-link to="/samples" @click="isOpenMenu = false">Details</router-link>
              </li>
              <li class="head-24-sb">
                <router-link to="/projects" class="workspace-btn" @click="isOpenMenu = false">
                  나만의 브랜딩 만들기
                </router-link>
              </li>
              <li v-if="store.isLogined">
                <button type="button" @click="store.logout(), (isOpenMenu = false)">
                  로그아웃
                </button>
              </li>
              <li>
                <router-link
                  to="/"
                  class="header__logo sprite sprite--logo"
                  @click="isOpenMenu = false"
                >
                  MintPrime
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </template>
  </header>
</template>

<script setup>
import DropdownComponent from '@/components/DropdownComponent.vue';

import { ref } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const store = useUserStore();

const { userName } = storeToRefs(store);

const isOpenMenu = ref(false);

defineProps({
  nav: {
    type: Boolean,
    default: true,
  },
});
</script>

<style scoped>
header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 68px;
  padding: 0 32px;
  background-color: var(--White);
}
.header__nav {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.header__nav ul,
.header__nav li {
  display: flex;
  gap: 0 32px;
}
.header__nav li:not(:first-child)::before {
  content: '';
  display: block;
  height: calc(100% - 4px);
  border-left: 1px solid var(--Gray-70);
  align-self: center;
}
.header__nav a {
  color: var(--Gray-70);
  pointer-events: auto;
}
.header__nav a:hover {
  color: var(--Gray-50);
}
header > div {
  display: flex;
  align-items: center;
  gap: 28px;
}
header > div > a {
  padding: 10px 18px;
  border-radius: 48px;
  border: 1px solid var(--Gray-70);
  color: var(--Gray-70);
}
header > div > a:hover {
  background-color: var(--Key);
}

.drawer-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--White);
  z-index: 300;
}
.drawer-menu__head {
  display: flex;
  justify-content: flex-end;
  padding: 24px 16px;
}
.drawer-menu__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.drawer-menu__content p {
  padding: 24px 16px;
}
.drawer-menu__content nav {
  margin-top: 24px;
}
.drawer-menu__content nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}
.drawer-menu__content nav button {
  color: var(--Gray-70);
}
.drawer-menu__content nav .workspace-btn {
  padding: 15px 24px;
  border-radius: 48px;
  border: 1px solid var(--Gray-70);
  background: var(--Key);
}
</style>
