<template>
  <section
    v-click-outside="
      (e) => {
        if (isOpen === true) {
          e.stopPropagation();
          isOpen = false;
        }
      }
    "
    class="dropdown"
  >
    <button
      ref="btn"
      type="button"
      :class="['dropdown__btn', { on: isOpen }]"
      :disabled="attrs.disabled"
      @click.stop="isOpen = !isOpen"
    >
      <slot name="btn"></slot>
    </button>
    <ul
      ref="list"
      v-show="isOpen"
      :class="['dropdown__list', { 'scroll--inner': hasScroll }]"
      @click.stop="autoClose ? (isOpen = false) : null"
      @keyup.stop="autoClose ? (isOpen = false) : null"
    >
      <slot name="list"></slot>
    </ul>
  </section>
</template>

<script setup>
import { ref, onMounted, watch, useAttrs } from 'vue';

const attrs = useAttrs();

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  autoClose: {
    type: Boolean,
    default: true,
  },
  hasScroll: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);
const btn = ref(null);
const list = ref(null);

onMounted(() => {
  if (attrs.class === 'dropdown--user') {
    list.value.style.top = `${btn.value.clientHeight + 3}px`;
    list.value.style.right = '0';
  } else if (attrs.class === 'dropdown--project-card') {
    list.value.style.top = `${btn.value.clientHeight + 4}px`;
    list.value.style.left = `${btn.value.clientWidth / 2}px`;
  } else if (attrs.class === 'dropdown--editor-draw') {
    list.value.style.top = `${btn.value.clientHeight + 12}px`;
    list.value.style.left = '-35px';
  } else if (attrs.class === 'dropdown--design-card') {
    list.value.style.top = `${btn.value.clientHeight + 7}px`;
    list.value.style.left = '-7px';
  } else if (attrs.class?.includes('select--list-filter')) {
    list.value.style.top = `${btn.value.clientHeight + 8}px`;
  } else if (attrs.class === 'dropdown--datepicker') {
    list.value.style.top = `${btn.value.clientHeight + 9}px`;
    list.value.style.right = '0';
  }
});

watch(isOpen, () => {
  emit('update:modelValue', isOpen.value);
});
</script>

<style>
.dropdown {
  position: relative;
}
.dropdown__list {
  position: absolute;
  background-color: var(--White);
  z-index: 10;
  overflow: hidden;
}
.dropdown__list.scroll--inner {
  overflow-y: scroll;
}
.dropdown--project-card .dropdown__list,
.dropdown--design-card .dropdown__list {
  border-radius: 24px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 16px 30px 4px rgba(0, 0, 0, 0.1);
}
.dropdown--project-card .dropdown__list > li button,
.dropdown--design-card .dropdown__list > li button {
  padding: 12px 14px;
}
.dropdown--project-card .dropdown__list > li:first-child button,
.dropdown--design-card .dropdown__list > li:first-child button {
  padding-top: calc(10px + 12px);
}
.dropdown--project-card .dropdown__list > li:last-child button,
.dropdown--design-card .dropdown__list > li:last-child button {
  padding-bottom: calc(10px + 12px);
}
.dropdown--project-card .dropdown__list > li button:hover,
.dropdown--design-card .dropdown__list > li button:hover {
  background-color: var(--Gray-20);
}

.dropdown--user .dropdown__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 129px;
  padding: 10px 12px;
  border-radius: 48px;
  border: 1px solid var(--Black);
}
.dropdown--user .dropdown__list {
  width: 129px;
  padding: 0 16px;
  border-radius: 12px;
  border: 1px solid var(--Gray-30);
  background: var(--White);
}
.dropdown--user .dropdown__list > li {
  width: 100%;
}
.dropdown--user .dropdown__list > li + li {
  border-top: 1px solid var(--Gray-30);
}
.dropdown--user .dropdown__list > li a,
.dropdown--user .dropdown__list > li button {
  display: block;
  padding: 12px 0;
}

.dropdown--primary-select .dropdown__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  height: 52px;
  padding: 16px 14px 16px 16px;
  border-radius: 2px;
  border: 1px solid var(--Black);
}
.dropdown--primary-select .dropdown__btn.on {
  border-radius: 2px 2px 0 0;
}
.dropdown--primary-select .dropdown__btn:disabled {
  border: 1px solid var(--Gray-20);
  background-color: var(--Gray-20);
  color: var(--Gray-50);
}
.dropdown--primary-select.dropdown--error .dropdown__btn {
  border: 1px solid var(--Notice-1);
  color: var(--Notice-1);
}
.dropdown--primary-select .dropdown__list {
  width: 335px;
  border-radius: 0px 0px 2px 2px;
  border-block: 1px solid var(--Black);
  border-top: none;
}
.dropdown--primary-select .dropdown__list > li button {
  width: 100%;
  height: 52px;
  padding: 16px;
  border-inline: 1px solid var(--Black);
  text-align: start;
}
.dropdown--primary-select .dropdown__list > li button:hover {
  background-color: var(--Gray-20);
}
.dropdown--primary-select .dropdown__list > li + li button {
  border-top: 1px solid var(--Gray-30);
}
.editor .dropdown.dropdown--secondary-select {
  position: static;
}
.editor .dropdown--secondary-select .dropdown__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  padding: 2px 8px 2px 14px;
  border-radius: 8px;
  border: 1px solid var(--Gray-20);
  overflow: hidden;
}
.editor .dropdown--secondary-select .dropdown__list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--Gray-40);
}
.editor .dropdown--secondary-select .dropdown__list button {
  width: 100%;
  text-align: end;
}
.order-history .dropdown--secondary-select .dropdown__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--White);
  overflow: hidden;
}
.order-history .dropdown--secondary-select .dropdown__list {
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  border-radius: 8px;
  background-color: var(--White);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 16px 30px 4px rgba(0, 0, 0, 0.1);
}
.order-history .dropdown--secondary-select .dropdown__list button {
  width: 100%;
  padding: 12px 0px;
}

.dropdown--editor-draw {
  z-index: 10;
}
.dropdown--editor-draw .dropdown__btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 13px;
}
.dropdown--editor-draw .dropdown__list {
  width: 146px;
  border-radius: 3px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 16px 30px 4px rgba(0, 0, 0, 0.1);
  overflow: visible;
  z-index: auto;
}
.dropdown--editor-draw .dropdown__list::before {
  content: '';
  position: absolute;
  top: -17px;
  left: calc(146px / 2 - 26px / 2);
  display: block;
  width: 0;
  height: 0;
  border-bottom: calc(26px / 2 * 1.732) solid var(--White);
  border-left: calc(26px / 2) solid transparent;
  border-right: calc(26px / 2) solid transparent;
  filter: drop-shadow(0px 16px 30px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.08));
  z-index: -10;
}
.dropdown--editor-draw .dropdown__list button {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 4px 18px;
  overflow: hidden;
}
.dropdown--editor-draw .dropdown__list li:first-of-type button {
  padding-top: calc(4px + 12px);
  border-radius: 3px 3px 0 0;
}
.dropdown--editor-draw .dropdown__list li:last-of-type button {
  padding-bottom: calc(4px + 12px);
  border-radius: 0 0 3px 3px;
}
.dropdown--editor-draw .dropdown__list button:hover {
  background-color: var(--Black);
  color: var(--White);
}
.dropdown--datepicker .dropdown__list {
  border-radius: 2px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 10px 25px 4px rgba(0, 0, 0, 0.06);
}
</style>
