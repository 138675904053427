<template>
  <section v-if="isblocked" class="alert-mobile">
    <h1 class="alert-mobile__logo sprite sprite--logo">민트프라임</h1>
    <h2 class="alert-mobile__content body-16-sb">현재 페이지는 PC로 사용 가능합니다.</h2>
    <ButtonComponent
      type="btn"
      class="alert-mobile__button btn--black"
      title="PC버전"
      @click="unblocking"
    ></ButtonComponent>
  </section>
</template>

<script setup>
import ButtonComponent from '@/components/ButtonComponent.vue';

import { ref, onBeforeMount } from 'vue';
import { useEventListener } from '@vueuse/core';

const isMobile = ref(false);
const isblocked = ref(false);

const scrollY = document.body.style.top;

onBeforeMount(() => {
  if ('maxTouchPoints' in navigator) {
    isMobile.value = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    isMobile.value = navigator.msMaxTouchPoints > 0;
  } else if (window.matchMedia('(pointer:coarse)').matches) {
    isMobile.value = true;
  } else if (window.matchMedia('(any-pointer:coarse)').matches) {
    isMobile.value = true;
  }
  if (isMobile.value) {
    check2Block();
    useEventListener(window, 'resize', check2Block);
  }
});

function check2Block() {
  if (window.innerWidth < 1280) {
    isblocked.value = true;

    if (!document.body.style.cssText.length) {
      document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    width: 100%;`;
    }

    return;
  }

  isblocked.value = false;
}

function unblocking() {
  isblocked.value = false;

  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

  const viewportContent = 'width=1200px';
  document.querySelector("meta[name='viewport']").setAttribute('content', viewportContent);
}
</script>

<style scoped>
.alert-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 53px;
  background-color: var(--White);
  z-index: 300;
}
.alert-mobile__button {
  width: 280px;
  color: var(--Gray-30);
}
</style>
